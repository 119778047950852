<template>
  <div class="page-container stock-entry-page">
    <portal to="page-name">Entrada de produtos e serviços</portal>
    <div class="loading loading-lg mt-2" v-if="loading"></div>
    <div class="card card-page" v-else>
      <div class="card-header">
        <div class="card-title">Entrada de produtos e serviços</div>
      </div>
      <div class="card-body">
        <div v-if="finished">
          <small class="text-info">
            * Esta entrada já foi finalizada e não poderá ser alterada
          </small>
          <div class="card-detail">
            <div class="card-detail-title">Dados da entrada</div>
            <div class="card-detail-data">
              <div class="columns">
                <div class="column col-12 form-group">
                  <label class="form-label">Data da movimentação</label>
                  <span class="text-bold">
                    {{ form.date | date('DD/MM/YYYY HH:mm') }}
                  </span>
                </div>
                <div class="column col-12 form-group">
                  <label class="form-label">Fornecedor</label>
                  <span class="text-bold">
                    {{ form.provider ? form.provider.name : '' }}
                  </span>
                </div>
                <div class="column col-12 form-group">
                  <label class="form-label">Tipo de operação</label>
                  <span class="text-bold">
                    {{ form.operationType ? form.operationType.name : '' }}
                  </span>
                </div>
                <div class="column col-12 form-group">
                  <label class="form-label">Total da nota</label>
                  <span class="text-bold">
                    {{ form.total | currency }}
                  </span>
                </div>
                <div class="column col-12 form-group" v-if="form.notes">
                  <label class="form-label">Observações</label>
                  {{ form.notes }}
                </div>
              </div>
            </div>
          </div>
          <div class="card-detail">
            <div class="card-detail-title">Produtos</div>
            <table class="table">
              <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th class="text-center">Un. Entrada</th>
                <th class="text-center">Un. Saída</th>
                <th>Lote</th>
                <th>Validade</th>
                <th class="text-center">Quantidade</th>
                <th class="text-right">Valor Total</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, i) in form.products" :key="i">
                <td>{{ i + 1 }}</td>
                <td>{{ item.name }}</td>
                <td class="text-center">{{ item.inputUnit }}</td>
                <td class="text-center">{{ item.outputUnit }}</td>
                <td>{{ item.lot && item.lot.name ? item.lot.name : '' }}</td>
                <td>{{ item.lot && item.lot.name ? item.lot.validity : '' | date }}</td>
                <td class="text-center" width="50px">{{ item.quantity }}</td>
                <td class="text-right" width="110px">{{ item.total | currency }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else>
          <div class="columns form-group">
            <div class="column col-6 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.form.operationTypeId.$error}">
              <label class="form-label text-bold">Tipo de operação</label>
              <select id="operation-type" class="form-select"
                      v-model="form.operationTypeId" v-focus
                      @blur="$v.form.operationTypeId.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="item in operationTypes"
                        :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
              <template v-if="$v.form.operationTypeId.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.operationTypeId.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-6 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.providerId.$error}">
              <label class="form-label text-bold">Fornecedor</label>
              <st-autocomplete
                id="provider"
                ref="providerAutocomplete"
                v-model="provider.item"
                :items="provider.items"
                :auto-select-one-item="false"
                :disabled="!!provider.item"
                :loading="provider.loading"
                :get-label="getProviderLabel"
                placeholder="Nome, Razão social ou CNPJ"
                @item-selected="selectProviderItem"
                @update-items="updateProviderItems">
                <a slot-scope="scope" class="menu-item-tile">
                  <figure class="avatar">
                    <fa-icon :icon="['fal', 'industry-alt']"></fa-icon>
                  </figure>
                  <div class="name"
                       v-html="scope.highlight(scope.item.name)"></div>
                </a>
                <button v-if="provider.item && provider.item.name"
                        slot="action" @click="removeProvider"
                        class="btn btn-icon btn-action btn-gray input-group-btn">
                  <fa-icon :icon="['fal', 'times']"></fa-icon>
                </button>
                <button tabindex="-1" slot="action" v-else
                        class="btn btn-icon btn-action input-group-btn btn-neutral">
                  <fa-icon :icon="['fal', 'search']"></fa-icon>
                </button>
              </st-autocomplete>
              <template v-if="$v.form.providerId.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.providerId.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-2 col-md-6 col-sm-12"
                 :class="{'has-error': $v.form.issueDate.$error}">
              <label class="form-label">Data da emissão</label>
              <dx-input-date id="issue-date" class="form-input text-center"
                             @blur="$v.form.issueDate.$touch()"
                             @focus="setIssueDate"
                             v-model="form.issueDate"/>
              <template v-if="$v.form.issueDate.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.issueDate.required">Campo obrigatório</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.issueDate.date">Data inválida</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.issueDate.minDate">Data vencida</div>
              </template>
            </div>
            <div class="column col-2 col-md-6 col-sm-12"
                 :class="{'has-error': $v.form.entryDate.$error}">
              <label class="form-label">Data da entrada</label>
              <dx-input-date id="entry-date" class="form-input text-center"
                             @blur="$v.form.entryDate.$touch()"
                             @focus="setEntryDate"
                             v-model="form.entryDate"/>
              <template v-if="$v.form.entryDate.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.entryDate.required">Campo obrigatório</div>
                <div class="form-input-hint"
                     v-if="!$v.form.entryDate.date">Data inválida</div>
                <div class="form-input-hint"
                     v-else-if="!$v.form.entryDate.minDate">Data vencida</div>
              </template>
            </div>
            <div class="column col-3 col-md-12 col-sm-12 form-group"
                 :class="{'has-error': $v.form.invoice.$error}">
              <label for="invoice" class="form-label text-bold">Número da Nota</label>
              <input type="text" id="invoice" name="invoice"
                     v-model="form.invoice" class="form-input"
                     @blur="$v.form.invoice.$touch()" autocomplete="nope">
              <template v-if="$v.form.invoice.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.invoice.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-1 col-md-12 col-sm-12 form-group">
              <label for="series" class="form-label">Série</label>
              <input type="text" id="series" name="series" maxlength="3"
                     v-model="form.series" class="form-input text-center"
                     autocomplete="nope">
            </div>
          </div>
          <div class="divider" data-content="Cálculo do imposto" />
          <div class="columns">
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Base de cálc. ICMS</label>
              <dx-input-number class="form-input" :precision="2"
                               v-model="form.icmsBase" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Valor do ICMS</label>
              <dx-input-number class="form-input" :precision="2"
                               v-model="form.icmsValue" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Base cálc. ICMS Sub.</label>
              <dx-input-number class="form-input" :precision="2"
                               v-model="form.icmsBaseSub" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Valor do ICMS Sub.</label>
              <dx-input-number class="form-input"
                               v-model="form.icmsValueSub"
                               :precision="2" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Valor do frete</label>
              <dx-input-number class="form-input"
                               v-model="form.freightValue"
                               :precision="2" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Valor do seguro</label>
              <dx-input-number class="form-input"
                               v-model="form.safeValue"
                               :precision="2" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Desconto</label>
              <dx-input-number class="form-input"
                               v-model="form.discountValue"
                               :precision="2" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Outras despesas</label>
              <dx-input-number class="form-input"
                               v-model="form.othersValue"
                               :precision="2" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label">Valor total do IPI</label>
              <dx-input-number class="form-input"
                               v-model="form.ipiValue"
                               :precision="2" />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.form.total.$error}">
              <label class="form-label text-bold">Valor total da nota</label>
              <dx-input-number class="form-input"
                               v-model="form.total"
                               @blur="$v.form.total.$touch()"
                               :precision="2" />
              <template v-if="$v.form.total.$error">
                <div class="form-input-hint"
                     v-if="!$v.form.total.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label text-bold">Total das parcelas</label>
              <dx-input-number class="form-input text-bold text-primary"
                               readonly
                               :value="installmentTotal"
                               :precision="2"
                               tabindex="-1"
              />
            </div>
            <div class="column col-2 col-md-6 col-sm-12 form-group">
              <label class="form-label text-bold">Total dos produtos</label>
              <dx-input-number class="form-input text-bold text-primary"
                               readonly
                               :value="totalItems"
                               :precision="2"
                               tabindex="-1"
              />
            </div>
          </div>
          <div class="divider" data-content="Dados financeiros" />
          <div class="columns">
            <div class="column col-4 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.payment.methodId.$error}">
              <label class="form-label">Forma de pagamento</label>
              <select id="method" class="form-select" v-model="payment.methodId">
                <option value="">[Selecione]</option>
                <option v-for="item in payment.methods" :value="item.id" :key="item.id">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="column col-2 col-sm-12 form-group">
              <label class="form-label">Parcela(s)</label>
              <select
                      class="form-select"
                      v-model="payment.installment"
                      :disabled="payment.paid">
                <option v-for="value in 12" :value="value" :key="value">{{ value }}</option>
              </select>
            </div>
            <div class="column col-2 form-group"
                 :class="{'has-error': $v.payment.dueDate.$error}">
              <label class="form-label">Data</label>
              <dx-input-date
                class="form-input text-center"
                @blur="$v.payment.dueDate.$touch()"
                @focus="setDueDate"
                v-model="payment.dueDate"
              />
              <template v-if="$v.payment.dueDate.$error">
                <div class="form-input-hint"
                     v-if="!$v.payment.dueDate.date">Data inválida</div>
                <div class="form-input-hint"
                     v-else-if="!$v.payment.dueDate.minDate">Data vencida</div>
              </template>
            </div>
            <div class="column col-2 col-sm-12 form-group"
                 :class="{'has-error': $v.payment.value.$error}">
              <label class="form-label">Valor</label>
              <dx-input-number id="value" v-model="payment.value"
                               class="form-input text-right"
                               @focus="payment.value = totalOpened"
                               :precision="2" maxlength="7"/>
            </div>
            <div class="column form-group mb-2"
                 style="display: flex; align-items: flex-end">
              <button class="btn btn-icon btn-icon-left btn-block btn-primary"
                      @click="addInstallment">
                <fa-icon :icon="['fal', 'plus-circle']" />Adicionar
              </button>
            </div>
          </div>
          <div class="empty" v-if="form.installments.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Formas de pagamento</p>
            <p class="empty-subtitle">
              Nenhuma forma de pagamento adicionada. Utilize os campos acima.
            </p>
          </div>
          <div class="payment-body" v-else>
            <table class="table table-hover">
              <thead>
              <tr>
                <th style="width:30px">#</th>
                <th>Forma de pagamento</th>
                <th class="text-center">Data</th>
                <th class="text-right">Valor</th>
                <th style="width:60px"></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(installment, i) in form.installments" :key="i">
                <td>{{ i + 1 }}</td>
                <td>
                  {{ installment.method.name }}
                  <small v-if="installment.method.type === 'credit_card'">
                    ({{ installment.method.installment.current }})
                  </small>
                </td>
                <td class="text-center">{{ installment.dueDate | date }}</td>
                <td class="text-right">{{ installment.value | currency }}</td>
                <td class="text-right">
                  <button slot="action" @click="removeInstallment(installment)"
                          class="btn btn-gray btn-action btn-icon btn-sm">
                    <fa-icon :icon="['fal', 'times']"/>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="divider" data-content="Lista de produtos" />
          <div class="columns">
            <div class="column col-5 col-md-6 col-sm-12 form-group"
                 :class="{'has-error': $v.placeId.$error}">
              <label class="form-label">Local de destino</label>
              <select id="origin-place" class="form-select"
                      v-model="placeId" ref="place"
                      @blur="$v.placeId.$touch()">
                <option value="">[Selecione]</option>
                <option v-for="item in places"
                        :value="item.id" :key="item.id">{{ item.name }}</option>
              </select>
              <template v-if="$v.placeId.$error">
                <div class="form-input-hint"
                     v-if="!$v.placeId.required">Campo obrigatório</div>
              </template>
            </div>
            <div class="column col-7 col-md-12 col-sm-12 form-group">
              <label class="form-label">Nome do produto</label>
              <st-autocomplete
                id="product"
                ref="productAutocomplete"
                v-model="product.item"
                :items="product.items"
                :disabled="!placeId"
                :loading="product.loading"
                :auto-select-one-item="false"
                :get-label="getProductLabel"
                placeholder="Informe o código de barras, nome ou lote"
                @item-selected="selectProductItem"
                @focus="product.items = []"
                @update-items="updateProductItems">
                <a slot-scope="scope" class="menu-item-tile">
                  <figure class="avatar">
                    <fa-icon :icon="['fal', 'box']"></fa-icon>
                  </figure>
                  <div class="name"
                       v-html="scope.highlight(scope.item.name)"></div>
                </a>
                <button tabindex="-1" slot="action"
                        class="btn btn-icon btn-action input-group-btn btn-neutral"
                        :disabled="!placeId">
                  <fa-icon :icon="['fal', 'search']"></fa-icon>
                </button>
              </st-autocomplete>
            </div>
          </div>
          <div class="empty" v-if="form.products.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="2x"/>
            </div>
            <p class="empty-title h6">Produtos</p>
            <p class="empty-subtitle">
              Nenhum produto adicionado. Utilize o campo acima para realizar a busca.
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th class="hide-sm" width="40px">#</th>
              <th>Produto</th>
              <th class="text-center">Unidade</th>
              <th>Lote</th>
              <th class="text-center">Validade</th>
              <th class="text-right">Quantidade</th>
              <th class="text-right">Valor Total</th>
              <th/>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in form.products" :key="i">
              <td class="hide-sm">{{ i + 1 }}</td>
              <td class="hover-link">
                {{ item.name }}
                <small class="text-info">
                  ({{ item.place ? item.place.name : '' }})
                </small>
              </td>
              <td class="text-center">{{ measure.getName(item.inputUnit) }}</td>
              <td style="max-width: 90px">
                <input type="text" class="form-input"
                       v-model="item.lot.name"
                       :disabled="!item.manageLot"
                       placeholder="AX472MF1">
              </td>
              <td class="text-center" style="width: 110px">
                <dx-input-date class="form-input text-center"
                               :class="{'is-error': $v.form.products.$each[i].lot.validity.$error}"
                               :disabled="!item.manageLot"
                               v-model="item.lot.validity"/>
              </td>
              <td class="text-right" style="width: 120px">
                <dx-input-number v-model="item.quantity"
                                 :class="{'is-error': $v.form.products.$each[i].quantity.$error}"
                                 class="form-input"
                                 :precision="3" />
              </td>
              <td class="text-right" style="width: 120px">
                <dx-input-number v-model="item.total"
                                 :class="{'is-error': $v.form.products.$each[i].total.$error}"
                                 class="form-input"
                                 :precision="2" />
              </td>
              <td class="text-right" style="width: 50px">
                <button class="btn btn-sm btn-action btn-icon btn-error"
                        @click="removeItem(item.id, i)">
                  <fa-icon :icon="['fal', 'times']"/>
                </button>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="card-footer">
        <button class="btn btn-primary mr-1"
                v-if="!finished"
                @click="save(false)"
                :disabled="finishing || saving"
                :class="{loading: saving}">
          Salvar
        </button>
        <button class="btn btn-warning mr-1"
                v-if="!finished"
                @click="save(true)"
                :disabled="finishing || saving"
                :class="{loading: finishing}">
          Finalizar
        </button>
        <button class="btn" @click="$router.back()">
          Voltar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { minValue, required } from 'vuelidate/src/validators';
import * as measure from 'src/data/measure-units';
import { FINISH_ENTRY } from '@/data/actions/modules/stock';
import { date, minDate } from '@/data/validators';
import moment from 'moment';
import formMixin from '../../../mixins/form';

export default {
  mixins: [formMixin],
  data() {
    return {
      measure,
      isNew: false,
      loading: false,
      finishing: false,
      saving: false,
      operationTypes: [],
      places: [],
      placeId: '',
      provider: {
        item: null,
        loading: null,
        items: [],
      },
      product: {
        item: null,
        loading: false,
        items: [],
      },
      payment: {
        methods: [],
        methodId: '',
        installment: 1,
        dueDate: '',
        value: '',
        paid: false,
      },
      form: this.blankForm(),
    };
  },
  watch: {
    'payment.methodId': {
      handler() {
        if (this.payment.methodId) {
          const method = this.payment.methods.find(({ id }) => id === this.payment.methodId);
          this.payment.installment = 1;
          this.payment.paid = method.documentType.type === 'money';
        }
      },
      deep: true,
    },
  },
  computed: {
    installmentTotal() {
      return Math.round(this.form.installments
        .reduce((a, b) => a + Number(b.value), 0) * 100) / 100;
    },
    totalOpened() {
      return this.form.total - this.installmentTotal;
    },
    totalItems() {
      return this.form.products.reduce((a, b) => a + Number(b.total), 0);
    },
    finished() {
      return this.form.status === 'finished';
    },
    canAccessFinishEntry() {
      if (FINISH_ENTRY) {
        return this.$can(FINISH_ENTRY);
      }
      return true;
    },
  },
  validations() {
    return {
      form: {
        issueDate: { required, date, minDate: minDate() },
        entryDate: { required, date, minDate: minDate() },
        operationTypeId: { required },
        providerId: { required },
        invoice: { required },
        total: { required },
        products: {
          $each: {
            lot: {
              validity: { date, minDate: minDate() },
            },
            quantity: { required, minValue: minValue(0.01) },
            total: { required, minValue: minValue(0.01) },
          },
        },
      },
      placeId: { required },
      payment: {
        methodId: { required },
        dueDate: { required, date, minDate: minDate() },
        value: { required, minValue: minValue(0.01) },
      },
    };
  },
  async mounted() {
    this.loadMethods();
    this.loadPlaces();
    this.loadOperationTypes();
    this.isNew = /create$/.test(this.$route.path);
    if (!this.isNew) {
      this.form.id = this.$route.params.id;
      this.load();
    }
  },
  methods: {
    setIssueDate() {
      if (!this.form.issueDate) {
        this.form.issueDate = moment().format('YYYY-MM-DD');
      }
    },
    setDueDate() {
      if (!this.payment.dueDate) {
        this.payment.dueDate = moment().format('YYYY-MM-DD');
      }
    },
    setEntryDate() {
      if (!this.form.entryDate) {
        this.form.entryDate = moment().format('YYYY-MM-DD');
      }
    },
    loadMethods() {
      const params = {
        active: true,
        limit: 0,
        types: ['expense', 'all'].join(','),
      };

      return this.$http.get('/payment-methods', { params })
        .then(({ data }) => {
          this.payment.methods = data.items;
        })
        .catch(() => {});
    },
    load() {
      this.loading = true;
      return this.$http
        .get(`/stock-entries/${this.form.id}`)
        .then(({ data }) => {
          this.setData(data);
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    loadPlaces() {
      this.places = [];

      const params = {
        active: true,
        limit: 0,
      };

      return this.$http
        .get('/places', { params })
        .then(({ data }) => {
          this.places = data.items;
        })
        .catch(() => {});
    },
    loadOperationTypes() {
      this.operationTypes = [];

      const params = {
        active: true,
        type: 'input',
        limit: 0,
      };

      return this.$http
        .get('/operation-types', { params })
        .then(({ data }) => {
          this.operationTypes = data.items;
        })
        .catch(() => {});
    },
    async save(finished = false) {
      if (finished && !this.canAccessFinishEntry) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      this.$v.form.$touch();
      if (this.$v.form.$error || this.saving || this.finishing) {
        return;
      }

      if (this.installmentTotal === 0 && finished) {
        this.$toast.show('Nenhuma forma de pagamento adicionada!', { type: 'error' });
        return;
      }

      const products = this.form.products.length;
      if ((!products || products.length === 0) && finished) {
        this.$toast.show('Nenhum produto adicionado!', { type: 'error' });
        return;
      }

      let validityLot = false;
      this.form.products.forEach((item) => {
        if (item.manageLot && (!item.lot.name || !item.lot.validity)) {
          validityLot = true;
        }
      });
      if (validityLot && finished) {
        this.$toast.show('Informe o lote e validade do(s) produto(s).', { type: 'error' });
        return;
      }

      if (this.totalOpened !== 0 && finished) {
        this.$toast.show('O valor financeiro está diferente do total da nota!',
          { type: 'error' });
        return;
      }

      if (finished) {
        this.finishing = true;
      } else {
        this.saving = true;
      }

      const entry = this.clone(this.form);
      entry.status = finished ? 'finished' : 'open';
      entry.productValue = this.totalItems;

      const request = this.isNew
        ? this.$http.post('/stock-entries', entry)
        : this.$http.put(`/stock-entries/${this.form.id}`, entry);

      // eslint-disable-next-line consistent-return
      return request
        .then(({ data }) => {
          if (this.isNew) {
            this.$router.replace(`/stock/entries/${data.id}/edit`);
            this.setData(data);
            this.isNew = false;
          }
          this.$toast.show('Registro salvo');
          this.form.status = finished ? 'finished' : 'open';
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.saving = false;
          this.finishing = false;
        });
    },
    addInstallment() {
      this.$v.payment.$touch();
      if (this.$v.payment.$error) {
        return;
      }

      const method = this.payment.methods
        .find(({ id }) => id === this.payment.methodId);

      const value = Math.round((this.payment.value / this.payment.installment) * 100) / 100;
      let rest = this.payment.value - (value * this.payment.installment);

      for (let i = 0; i < this.payment.installment; i += 1) {
        this.form.installments.push({
          dueDate: moment(this.payment.dueDate).add(i, 'month').format('YYYY-MM-DD'),
          method: {
            id: method.id,
            name: method.name,
            type: method.documentType.type,
            compensation: method.compensation,
            documentType: method.documentType,
            bankAccount: method.bankAccount,
            installments: method.installments,
            installment: {
              total: this.payment.installment,
              current: i + 1,
            },
            paid: method.documentType.type === 'money',
          },
          value: value + rest,
        });
        rest = 0;
      }

      this.clearPayment();
    },
    clearPayment() {
      this.$v.payment.$reset();
      this.payment.methodId = '';
      this.payment.installment = 1;
      this.payment.dueDate = '';
      this.payment.value = '';
    },
    removeInstallment(item) {
      const idx = this.form.installments.indexOf(item);
      this.form.installments.splice(idx, 1);
    },
    selectProviderItem(item) {
      this.form.providerId = item.id;
    },
    getProviderLabel(item) {
      return item ? item.name : '';
    },
    updateProviderItems(text) {
      const value = text.trim();

      const params = {
        search: value,
        limit: 10,
      };

      if (value.length) {
        this.provider.loading = true;
        this.$http
          .get('/providers', { params })
          .then(({ data }) => {
            this.provider.items = data.items;
          })
          .catch(() => {})
          .finally(() => {
            this.provider.loading = false;
          });
      } else {
        this.provider.items = [];
      }
    },
    removeProvider() {
      this.$refs.providerAutocomplete.searchText = null;
      this.form.providerId = '';
      this.provider.items = [];
      this.provider.item = null;
    },
    getProductLabel(item) {
      return item ? item.name : '';
    },
    updateProductItems(text) {
      const value = text.trim();

      const params = {
        types: ['material', 'medicine', 'others'],
        search: value,
        limit: 10,
      };

      if (value.length) {
        this.product.loading = true;
        this.$http
          .get('/procedures', { params })
          .then(({ data }) => {
            if (data.items && data.items[0].selected) {
              this.selectProductItem(data.items[0]);
            } else {
              this.product.items = data.items;
            }
          })
          .catch(() => {})
          .finally(() => {
            this.product.loading = false;
          });
      } else {
        this.product.items = [];
      }
    },
    selectProductItem(item) {
      this.$v.placeId.$touch();
      if (this.$v.placeId.$error) {
        return;
      }

      this.form.products.push({
        place: this.getPlace(this.placeId),
        id: item.id,
        name: item.name,
        complement: item.complement,
        type: item.type,
        inputUnit: item.inputUnit,
        manageLot: item.manageLot,
        lot: {
          id: '',
          name: '',
          validity: '',
        },
        quantity: 1,
        total: '',
      });
      this.$refs.productAutocomplete.searchText = null;
      this.$nextTick(() => {
        this.$refs.productAutocomplete.focus();
      });
    },
    removeItem(id, i) {
      this.form.products.splice(i, 1);
    },
    setData(data) {
      this.form = data;
      this.form.products = data.products
        .map((item) => {
          item.lot = item.lot || {};
          return item;
        });
      if (data.provider && data.provider.id) {
        this.form.providerId = data.provider.id;
        this.provider.item = data.provider;
      }
    },
    getPlace(id) {
      return this.places.find(place => place.id === id);
    },
    blankForm() {
      return {
        id: '',
        status: '',
        operationTypeId: '',
        issueDate: '',
        entryDate: '',
        providerId: '',
        invoice: '',
        series: '',
        icmsBase: '',
        icmsValue: '',
        icmsBaseSub: '',
        icmsValueSub: '',
        productValue: '',
        freightValue: '',
        discountValue: '',
        othersValue: '',
        ipiValue: '',
        safeValue: '',
        total: '',
        products: [],
        installments: [],
        notes: '',
      };
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";
.stock-entry-page {
  .card-detail {
    background-color: lighten($gray-color-light, 5%);
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing;
    .card-detail-title {
      font-size: $font-size-lg;
      margin-bottom: $layout-spacing;
    }
  }
  .empty {
    padding: $layout-spacing-lg * 2;
  }
  .divider::after {
    padding: 0;
  }
  .form-checkbox {
    line-height: 1rem;
    margin: 0;
    min-height: 0;
    i {
      top: 0.15rem;
    }
  }
  .payment-body {
    background-color: $gray-color-ultra-light;
    border: $border-color solid $border-width;
    border-radius: $border-radius;
    padding: $layout-spacing;
    .empty {
      padding: $layout-spacing-sm;
      .empty-icon {
        margin-bottom: $layout-spacing-sm;
      }
      .empty-subtitle {
        margin: 0;
      }
    }
  }
}
</style>
